import { HeroLayout } from 'component-library'
import { StaticImage } from 'gatsby-plugin-image'
import content from '../../content/research'
import CTALink from '../CTALink/CTALink'
import SpotlightSection from '../SpotlightSection/SpotlightSection'
import { sendCustomGAEvent } from 'shared-utilities'
import LinkComponent from '../LinkComponent/LinkComponent'
import { spotlights } from 'shared-content'
import './ResearchHero.scss'

const HERO_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_transparent.png`

/* Content */
const { title, description, image, ctas } = content.heroSection
const spotlight = spotlights.research_page

export default function ResearchHero() {
  return (
    <div className="ResearchHero Hero__Background__Container">
      <StaticImage
        src={HERO_BACKGROUND}
        alt=""
        loading="eager"
        placeholder="none"
        quality={100}
        objectPosition="top left"
        className="Hero__Background__Image Sticky__Image"
      />
      {/* Hero Main */}
      <section>
        <HeroLayout
          title={title}
          description={description}
          imageUrl={image}
          bottomContent={
            <div className="ResearchHero__bottom-content">
              <div className="ResearchHero__cta-primary">
                <LinkComponent
                  className="Button__primary--blue"
                  href={ctas.primary.link}
                  onClick={() => {
                    sendCustomGAEvent('click', {
                      event_category: 'research_page_hero',
                      event_label: ctas.primary.text,
                      value: ctas.primary.link,
                    })
                  }}
                >
                  <span>{ctas.primary.text}</span>
                </LinkComponent>
              </div>

              <div className="ResearchHero__cta-secondary">
                <CTALink
                  label={ctas.secondary.text}
                  href={ctas.secondary.link}
                  gaEventCategory="research_page_hero"
                />
              </div>
            </div>
          }
        />
      </section>

      {/* Spotlight */}
      {!spotlight.hidden && (
        <SpotlightSection announcements={spotlight.announcements} />
      )}
    </div>
  )
}
