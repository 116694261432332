import { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import MarkdownText from '../MarkdownText/MarkdownText'
import researchContent from '../../content/research'
import LinkComponent from '../LinkComponent/LinkComponent'
import { sendCustomGAEvent } from 'shared-utilities'
import { Button } from 'component-library'
import './ResearchPapers.scss'

const { title, description, papers } = researchContent.researchPapers
const HERO_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_grey.png`

const ResearchCard = (paper: {
  description: string
  callToAction: {
    label: string
    link: string
    icon: string
    isAnimated?: boolean
  }[]
}) => (
  <div className="ResearchPapers__Card">
    <MarkdownText
      text={paper.description}
      className="text-body font-quicksand"
    />
    <div className="flex">
      {paper.callToAction.map((action, index) => (
        <LinkComponent
          href={action.link}
          key={index}
          testId={`${index}`}
          className={`ResearchPapers__Card__Link ${
            action.isAnimated ? 'Bottom__link' : ''
          }`}
          onClick={() => {
            sendCustomGAEvent('click', {
              event_category: 'research_page_papers',
              event_label: action.label,
              value: action.link,
            })
          }}
        >
          <span>{action.label}</span>
          <i className={`bx ${action.icon}`}></i>
        </LinkComponent>
      ))}
    </div>
  </div>
)

const ResearchPapers = () => {
  const [showAllPapersMobile, setShowAllPapersMobile] = useState<boolean>(false)
  return (
    <div className="ResearchPapers Hero__Background__Container">
      <StaticImage
        src={HERO_BACKGROUND}
        alt=""
        loading="eager"
        placeholder="none"
        quality={100}
        objectPosition="top left"
        className="Hero__Background__Image"
      />
      <div className="my-10 container">
        <h3 className="text-h3 mb-3">{title}</h3>
        <MarkdownText
          text={description}
          testId="research-section-description"
          className="ResearchPapers__description"
          gaEventCategory="research_page_papers"
        />
        <div className="ResearchPapers_Cards--desktop">
          {papers.map((paper) => ResearchCard(paper))}
        </div>
        <div className="ResearchPapers_Cards--mobile">
          {(showAllPapersMobile ? papers : papers.slice(0, 2)).map((paper) =>
            ResearchCard(paper)
          )}
        </div>
        <Button
          label={showAllPapersMobile ? 'Show less papers' : 'Show more papers'}
          type="text"
          className="no-underline mt-4 md:hidden"
          rightIcon={
            showAllPapersMobile ? (
              <i className="bx bxs-chevron-up"></i>
            ) : (
              <i className="bx bxs-chevron-down"></i>
            )
          }
          onClick={() => setShowAllPapersMobile(!showAllPapersMobile)}
        />
      </div>
    </div>
  )
}

export default ResearchPapers
