import LinkComponent from '../LinkComponent/LinkComponent'
import { sendCustomGAEvent } from 'shared-utilities'
import './CTALink.scss'

const CTALink = ({
  label,
  href,
  gaEventCategory,
}: {
  label: string
  href: string
  gaEventCategory?: string
}) => {
  return (
    <LinkComponent
      className="CTALink"
      href={href}
      onClick={
        gaEventCategory
          ? () => {
              sendCustomGAEvent('click', {
                event_category: gaEventCategory,
                event_label: label,
                value: href,
              })
            }
          : undefined
      }
    >
      <span data-testid="cta-link-label">{label}</span>{' '}
      <i
        data-testid="cta-link-icon"
        className="bx bx-chevron-right text-[24px]"
      ></i>
    </LinkComponent>
  )
}

export default CTALink
